import {
  HttpBackend,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  public environments: {
    environment: string;
    api: string;
  };

  constructor(public http: HttpBackend) {}

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.handle(this.getRequest()).subscribe({
        next: (response: HttpResponse<any>) => {
          this.environments = this.extractBody(response);

          if (this.environments) {
            if (this.environments.environment) {
              Sentry.addEventProcessor((event) => {
                event.environment = this.environments.environment;
                return event;
              });
            }

            resolve(null);
          }
        },
        error: (err: unknown) => {
          reject(err);
        },
      });
    });
  }

  private getHeaders(): HttpHeaders {
    const header = new HttpHeaders();
    header.set('Cache-Control', 'no-cache');
    header.set('Pragma', 'no-cache');

    return header;
  }

  private getRequest(): HttpRequest<any> {
    return new HttpRequest('GET', './assets/environment.json', null, {
      headers: this.getHeaders(),
    });
  }

  private extractBody(response: HttpResponse<any>): any {
    return response.body;
  }
}
