import { Injectable } from '@angular/core';
import { NotifierService } from 'gramli-angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomNotifierService {
  constructor(
    private readonly notifier: NotifierService,
    private readonly translate: TranslateService,
  ) {}

  public notifyWithTrans(type: any, key: string, interpolateParams?: any) {
    this.notifier.notify(type, this.translate.instant(key, interpolateParams));
  }
}

export enum notifyType {
  success = 'success',
  info = 'info',
  error = 'error',
}
