import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BaseSubscribeClass implements OnDestroy {
  protected readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
