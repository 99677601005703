import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EnvironmentService } from '../environment/environment.service';
import { CustomNotifierService, notifyType } from './custom-notifier.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private notifier: CustomNotifierService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private translate: TranslateService,
  ) {}

  private formatErrors = (error: unknown) => {
    console.error('API Error', error);

    const notify = (errorText: string): void => {
      this.notifier.notifyWithTrans(notifyType.error, errorText);
    };

    if (error instanceof HttpErrorResponse && error.error?.message) {
      const messages = Array.isArray(error.error.message)
        ? error.error.message
        : [error.error.message];
      for (const message of messages) {
        this.translate.get(message).subscribe((msg: string) => notify(msg));
      }
    } else if (error instanceof HttpErrorResponse && error.status === 0) {
      this.translate
        .get('error-network')
        .subscribe((msg: string) => notify(msg));
    } else {
      switch (error instanceof HttpErrorResponse && error.error?.status) {
        case 500: {
          this.translate
            .get('error-500')
            .subscribe((msg: string) => notify(msg));
          break;
        }
        case 401: {
          this.translate
            .get('error-401')
            .subscribe((msg: string) => notify(msg));
          break;
        }
        case 404: {
          this.translate
            .get('error-404')
            .subscribe((msg: string) => notify(msg));
          break;
        }
        case 422: {
          this.translate
            .get('error-422')
            .subscribe((msg: string) => notify(msg));
          break;
        }
        case 403: {
          this.translate
            .get('error-403')
            .subscribe((msg: string) => notify(msg));
          break;
        }
        default: {
          this.translate
            .get('error-def')
            .subscribe((msg: string) => notify(msg));
          break;
        }
      }
    }

    return throwError(() => error);
  };

  delete<T = any>(path: string, params?: object): Observable<T> {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
      params: httpParams,
    };

    return this.http
      .delete<T>(`${this.environmentService.environments.api}${path}`, options)
      .pipe(catchError((e: unknown) => this.formatErrors(e)));
  }

  get<T = any>(
    path: string,
    params?: object,
    responseType: 'json' | 'text' = 'json',
  ): Observable<T> {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return this.http
      .get<T>(`${this.environmentService.environments.api}${path}`, {
        params: httpParams,
        responseType: responseType as any,
      })
      .pipe(catchError((e: unknown) => this.formatErrors(e)));
  }

  post<T = any>(
    path: string,
    body: any,
    params?: object,
    formData: 'json' | 'formData' = 'json',
  ): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    };

    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return this.http
      .post<T>(
        `${this.environmentService.environments.api}${path}`,
        formData === 'json' ? JSON.stringify(body) : body,
        formData === 'json'
          ? {
              ...options,
              params: httpParams,
            }
          : {},
      )
      .pipe(catchError((e: unknown) => this.formatErrors(e)));
  }

  put<T = any>(
    path: string,
    body: object = {},
    params?: object,
    formData: 'json' | 'formData' = 'json',
  ): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return this.http
      .put<T>(
        `${this.environmentService.environments.api}${path}`,
        formData === 'json' ? JSON.stringify(body) : body,
        formData === 'json'
          ? {
              ...options,
              params: httpParams,
            }
          : {},
      )
      .pipe(catchError((e: unknown) => this.formatErrors(e)));
  }

  patch<T = any>(
    path: string,
    body: object = {},
    params?: object,
    formData: 'json' | 'formData' = 'json',
  ): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return this.http
      .patch<T>(
        `${this.environmentService.environments.api}${path}`,
        formData === 'json' ? JSON.stringify(body) : body,
        formData === 'json'
          ? {
              ...options,
              params: httpParams,
            }
          : {},
      )
      .pipe(catchError((e: unknown) => this.formatErrors(e)));
  }
}
